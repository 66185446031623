import { useContext, useState, useEffect, useRef } from "react";
import Calendar from "../components/Calendar";
import { Image, Card, Button } from "react-bootstrap";
import { Context } from "..";
import { Spinner } from "react-bootstrap";
import { fetchCitizensHearingProgram } from "../http/activitiesAPI";
import ShareButton from "../components/UI/ShareButton";
import document from "../assets/document.jpg";

const CitizensHearingProgram = () => {
	const [loading, setLoading] = useState(true);

	const printRef = useRef(null);
	const handlePrint = () => {
		const originalBodyContent = document.body.innerHTML;
		document.body.innerHTML = printRef.current.outerHTML;
		window.print();

		onafterprint = (e) => {
			console.log(e);

			document.body.innerHTML = originalBodyContent;
			window.location.reload();
		};
	};

	// if (loading) {
	// 	return (
	// 		<div className='d-flex align-items-center justify-content-center py-5'>
	// 			<Spinner animation={"grow"} />
	// 		</div>
	// 	);
	// }
	return (
		<div>
			<div className='d-flex flex-xl-row flex-column justify-content-center px-xl-4'>
				<div className='d-flex flex-column col-xl-8 col-12 px-4'>
					<div className='py-4 block-title text-center'>
						<span className='text-primary'>Plată</span>
					</div>
					<div
						className='d-flex flex-column flex-md-row align-items-center justify-content-center mb-3'
						ref={printRef}>
						<div className='d-flex flex-md-row flex-column justify-content-center align-items-center'>
							<form
								class='form'
								action='https://oplata.md/payment/invoice'
								method='post'>
								<div className='d-flex flex-column flex-md-row justify-content-center'>
									<input
										id='bill'
										class='rounded border-1 border-primary py-1 mb-2 mb-md-0'
										maxLength='7'
										name='bill'
										type='text'
										autofocus=''
										placeholder='Ex. 12345'
									/>
									<div className='ms-md-2 ms-0'>
										<input
											name='key'
											type='hidden'
											value='DFA1C403-6887-E911-9421-001E6878AC13'
										/>
										<input
											name='id'
											type='hidden'
											value='1032'
										/>
										<input
											name='lang'
											type='hidden'
											value='ru'
										/>
										<Button
											class='btn btn-primary text-center w-100'
											type='submit'>
											Confirmare
										</Button>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div className='d-flex flex-column flex-md-row justify-content-center align-items-center'>
						<Image
							style={{ maxWidth: 600, height: "auto" }}
							src={document}
						/>
					</div>
					<div>
						<h4>
							<strong>Cum să plătiți?</strong>
						</h4>
						<p>
							<strong>NOTĂ:</strong> Serviciul „Plătiți factura” este destinat exclusiv consumatorilor casnici (persoane
							fizice).
						</p>
						<p>Modalități de plată a facturilor:</p>
						<ul>
							<li>Online, folosind cardul bancar prin platforma Paynet.</li>
							<li>Online, folosind portofelul electronic Paynet.</li>
							<li>Personal, prin terminalele Paynet.</li>
							<li>Personal, în oficiile „Poșta Moldovei”.</li>
						</ul>
						<p>Aveți întrebări? Contactați-ne:</p>
						<p>Telefon: 0231 3-23-34</p>
						<p>E-mail: info@drcd.md</p>
						<p>Adresa: Moldova, Bălți, str. Decebal, 126</p>
					</div>
					<div className='d-flex flex-xl-row flex-column justify-content-end align-items-center w-100'>
						<div className='d-flex flex-row align-items-center justify-content-end ps-1 pe-5'>
							<i
								role='button'
								onClick={() => handlePrint()}
								className='bi bi-printer text-primary icon-3'
								style={{
									fontWeight: "600 !important",
									WebkitTextStroke: 1,
								}}
							/>
							<ShareButton />
						</div>
					</div>
				</div>
				<Calendar />
			</div>
		</div>
	);
};

export default CitizensHearingProgram;
